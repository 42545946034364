import { PersonDso } from "../models/person";


export async function lastUpdatePersonByDate(date: Date): Promise<PersonDso[]> {
  return new Promise((resolve) => {
    return resolve([{
      national_id: '3849900317430',
      prefix: 'นาง',
      first_name: 'ดวงฤดี',
      last_name: 'พฤกษ์อุดม',
      job: 'ประกอบธุรกิจรับซื้อขายนํ้ามันปาล์ม',
      address: 'เลขที่ 456/67 ถนนตลาดใหม่ ตำบลตลาด อำเภอเมืองสุราษฎร์ธานี จังหวัดสุราษฎร์ธานี',
      case_number: 'ล.41/2561',
      plaintiff: 'บรืษัทประกันสินเชื่ออุตสาหกรรมขนาดย่อม',
      decision_result: 'ABSOLUTE_RECEIVERSHIP',
      decision_date: new Date('2018-01-15'),
      publish_date: new Date('2018-02-16'),
    }]);
  });
}

export async function searchPersonByNationalId(nationalId: string): Promise<PersonDso[]> {
  return new Promise((resolve) => {
    return resolve([{
      national_id: nationalId,
      prefix: 'นาง',
      first_name: 'ดวงฤดี',
      last_name: 'พฤกษ์อุดม',
      job: 'ประกอบธุรกิจรับซื้อขายนํ้ามันปาล์ม',
      address: 'เลขที่ 456/67 ถนนตลาดใหม่ ตำบลตลาด อำเภอเมืองสุราษฎร์ธานี จังหวัดสุราษฎร์ธานี',
      case_number: 'ล.41/2561',
      plaintiff: 'บรืษัทประกันสินเชื่ออุตสาหกรรมขนาดย่อม',
      decision_result: 'ABSOLUTE_RECEIVERSHIP',
      decision_date: new Date('2018-01-15'),
      publish_date: new Date('2018-02-16'),
    }]);
  });
}

export async function searchPersonByName(firstName: string, lastName: string, middleName?: string): Promise<PersonDso[]> {
  return new Promise((resolve) => {
    return resolve([{
      national_id: '3849900317430',
      prefix: 'นาง',
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      job: 'ประกอบธุรกิจรับซื้อขายนํ้ามันปาล์ม',
      address: 'เลขที่ 456/67 ถนนตลาดใหม่ ตำบลตลาด อำเภอเมืองสุราษฎร์ธานี จังหวัดสุราษฎร์ธานี',
      case_number: 'ล.41/2561',
      plaintiff: 'บรืษัทประกันสินเชื่ออุตสาหกรรมขนาดย่อม',
      decision_result: 'ABSOLUTE_RECEIVERSHIP',
      decision_date: new Date('2018-01-15'),
      publish_date: new Date('2018-02-16'),
    }]);
  });
}

export async function searchPersonByCaseNumber(caseNumber: string): Promise<PersonDso[]> {
  return new Promise((resolve) => {
    return resolve([{
      national_id: '3849900317430',
      prefix: 'นาง',
      first_name: 'ดวงฤดี',
      last_name: 'พฤกษ์อุดม',
      job: 'ประกอบธุรกิจรับซื้อขายนํ้ามันปาล์ม',
      address: 'เลขที่ 456/67 ถนนตลาดใหม่ ตำบลตลาด อำเภอเมืองสุราษฎร์ธานี จังหวัดสุราษฎร์ธานี',
      case_number: caseNumber,
      plaintiff: 'บรืษัทประกันสินเชื่ออุตสาหกรรมขนาดย่อม',
      decision_result: 'ABSOLUTE_RECEIVERSHIP',
      decision_date: new Date('2018-01-15'),
      publish_date: new Date('2018-02-16'),
    }]);
  });
}
