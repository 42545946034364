import './SignUp.scss';
import './Backdrop.scss';

import { Fragment, useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toastify';
import packageJson from "../../../package.json";
import logoLg from '../../assets/images/logo_lg.png'
import CardInfinitProgress from "../progress/CardInfinit";
import TextBoxWithValidator, { ReturnValue as TextBoxWithValidatorRv } from "../inputs/TextBoxWithValidator";
import { signUp } from "../../controllers/security";
import { UnprocessableEntityError, BadGatewayError, BadRequestError } from "../../errors/custom";
import { User } from "../../models/security";

interface Props {
  show: boolean;
  onLoginSuccess?: () => void;
  onLoginFail?: () => void;
  onPrivacyPolicyClick?: () => void;
  onAboutUsClick?: () => void;
  onForgotPasswordClick?: () => void;
}

export default function SignUpModal(props: Props) {
  const submitButtonRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [searchParams] = useSearchParams();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [name, setName] = useState<TextBoxWithValidatorRv>({ value: '', errorMessage: '' });
  const [phone, setPhone] = useState<TextBoxWithValidatorRv>({ value: '', errorMessage: '' });
  const [password, setPassword] = useState<TextBoxWithValidatorRv>({ value: '', errorMessage: '' });
  const [confirmPassword, setConfirmPassword] = useState<TextBoxWithValidatorRv>({ value: '', errorMessage: '' });
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    setIsSubmittable([name, phone, password, confirmPassword].map((cv) => { return cv.errorMessage === undefined; }).reduce((pv, cv) => { return pv && cv; }, true))
  }, [name, phone, password, confirmPassword])

  async function submitSignUp(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsProcessing(true);
    try {
      const user = await signUp(name.value, phone.value, password.value, searchParams.get('oobCode') ?? '')
      setUser(user);
    } catch (error: any) {
      if (error instanceof UnprocessableEntityError) {
        const err = error as UnprocessableEntityError;
        if (err.response.fields!.find((f) => { return f.field === 'oob_code' })) {
          toast.error('ไม่พบรหัสการเชิญ');
          console.error(`Couldn't find oob code while signing up.`);
        } else {
          toast.error('คำขอไม่ถูกต้อง');
          console.error('Invalid signing up json body error occurred while signing up.', err.response);
        }
        return;
      } else if (error instanceof BadRequestError) {
        const err = error as BadRequestError;
        if (err.response.error === 'E400001') {
          toast.error('รหัสการเชิญไม่ถูกต้อง');
          console.error('Invalid oob code.', error);
          return;
        }
      } else if (error instanceof BadGatewayError) {
        const err = error as BadGatewayError;
        toast.error('ไม่สามารถเชื่อมต่อกับเซิฟเวอร์ได้ กรุณาลองใหม่อีกครั้ง');
        console.error('Bad geteway error occurred while signing up.', error);
        return;
      }
      toast.error('พบข้อผิดพลาด');
      console.error('Unhandle error occurred while signing up.', error);
    } finally {
      setIsProcessing(false);
    }
  }

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => { }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 unauthorized-backdrop transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left transition-all sm:my-8 sm:mx-8 sm:max-w-lg">
                <h1 className="font-bold text-center text-2xl mb-5">
                  <div>
                    <img className='mx-auto' src={logoLg} alt="Kate Raengpetch" />
                  </div>
                </h1>
                <div className="relative bg-white rounded-lg shadow">
                  {!!user ? (
                    <div className="px-5 py-7 signup-form">
                      <h3 className="text-center">คุณสามารถลงชื่อเข้าสู่ระบบด้วยอีเมล '{user.email}' ได้แล้ว</h3>
                      <div>
                        <Link to="/signin" type="submit" className={"group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}>
                          เข้าสู่ระบบ
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="px-5 py-7 signup-form">
                      <form className="space-y-6" onSubmit={submitSignUp} noValidate>
                        <h3 className="text-center">กรุณากรอกข้อมูลเพิื่อสร้างบัญชีผู้ใช้</h3>
                        <TextBoxWithValidator label="ชื่อ-นามสกุล" pattern={/^[a-zA-Zก-๏\s]{4,50}$/} errorMessage='ต้องประกอบด้วยตัวอักษรภาษาไทย, อังกฤษ และเว้นวรรคเท่านั้น' value={name.value} onChange={(value) => { setName(value); }} />
                        <TextBoxWithValidator label="เบอร์โทรศัพท์" pattern={/^\+[0-9]{2}[0-9]{8,9}$/} errorMessage='ต้องอยู่ในรูปแบบ +(XX)XXXXXXXXX เท่านั้น' value={phone.value} onChange={(value) => { setPhone(value); }} />
                        <TextBoxWithValidator type='password' label="สร้างรหัสผ่าน" pattern={/^.{8,20}$/} equalTo={confirmPassword.value} errorMessage='ต้องมีความยาว 8-20 ตัวอักษร และตรงกับยืนยันรหัสผ่าน' value={password.value} onChange={(value) => { setPassword(value); }} />
                        <TextBoxWithValidator type='password' label="ยืนยันรหัสผ่าน" pattern={/^.{8,20}$/} equalTo={password.value} errorMessage='รหัสผ่านไม่ตรงกัน' value={confirmPassword.value} onChange={(value) => { setConfirmPassword(value); }} />
                        <div>
                          <button ref={submitButtonRef} type="submit" disabled={!isSubmittable} className={(!isSubmittable ? "opacity-50 cursor-not-allowed " : "") + "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}>
                            บันทึก
                          </button>
                        </div>
                      </form>
                      <CardInfinitProgress show={isProcessing}></CardInfinitProgress>
                    </div>
                  )}
                </div>
                <div className="py-5">
                  <div className="grid grid-cols-1 gap-1">
                    <div className="credit text-center">
                      ©Copyright. All Right Reserved 2018 - {new Date().getFullYear()}
                      <h5>Kate Raengpetch Law Office Co., Ltd.</h5>
                      <span className="text-blue-600 hover:text-blue-500 pointer" onClick={props.onPrivacyPolicyClick}>Privacy &amp; Policy</span> &nbsp;|&nbsp; <span className="text-blue-600 hover:text-blue-500 pointer" onClick={props.onAboutUsClick}>About us</span> &nbsp;|&nbsp; Web Application {packageJson.version}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div >
      </Dialog >
    </Transition.Root >
  )
}


