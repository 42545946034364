import './SignIn.scss';
import './Backdrop.scss';

import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { getAuth, signInWithEmailAndPassword, browserLocalPersistence, browserSessionPersistence } from "firebase/auth";
import { app as firebaseApp } from "../../services/firebase";
import packageJson from "../../../package.json";
import logoLg from '../../assets/images/logo_lg.png'
import CardInfinitProgress from "../progress/CardInfinit";
import { FirebaseError } from 'firebase/app';
import { toast } from 'react-toastify';


interface Props {
  show: boolean;
  onSignInSuccess?: () => void;
  onSignInFail?: () => void;
  onPrivacyPolicyClick?: () => void;
  onAboutUsClick?: () => void;
  onForgotPasswordClick?: () => void;
}

export default function SignInModal(props: Props) {
  const emailInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const passwordInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const rememberMeCheckBoxRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const submitButtonRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [isProcessing, setIsProcessing] = useState(false);

  async function signin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsProcessing(true);
    const auth = getAuth(firebaseApp);

    try {

      if (rememberMeCheckBoxRef.current.checked) {
        await auth.setPersistence(browserLocalPersistence);
      } else {
        await auth.setPersistence(browserSessionPersistence);
      }

      let email = emailInputRef.current.value;
      let password = passwordInputRef.current.value;

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log(userCredential)
      if (props.onSignInSuccess) {
        props.onSignInSuccess();
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/invalid-email' || error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
          toast.error('Invalid email and/or password.');
          console.warn('Invalid email and/or password.');
          return;
        }
      }
      toast.error('Unhandle error.');
      console.error('unhandle-signin-error', error);
    } finally {
      setIsProcessing(false);
    }
  }

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => { }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 unauthorized-backdrop transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left transition-all sm:my-8 sm:mx-8 sm:max-w-lg">
                <h1 className="font-bold text-center text-2xl mb-5">
                  <div>
                    <img className='mx-auto' src={logoLg} alt="Kate Raengpetch" />
                  </div>
                </h1>
                <div className="relative bg-white rounded-lg shadow">
                  <div className="px-5 py-7 signin-form">
                    <form className="space-y-6" onSubmit={signin} noValidate>
                      <h3 className="text-center">กรุณาลงชื่อเข้าใช้ระบบ
                      </h3>
                      <input type="hidden" name="remember" value="true" />
                      <div className="rounded-md shadow-sm">
                        <div>
                          <label htmlFor="email-address" className="sr-only">Email address</label>
                          <input ref={emailInputRef} id="email" name="email" type="email" autoComplete="email" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" placeholder="Email address" />
                        </div>
                        <div>
                          <label htmlFor="password" className="sr-only">Password</label>
                          <input ref={passwordInputRef} id="password" name="password" type="password" autoComplete="current-password" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" placeholder="Password" />
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input id="remember-me" ref={rememberMeCheckBoxRef} name="remember-me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                          <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900"> Remember me </label>
                        </div>

                        <div className="text-sm">
                          <button onClick={props.onForgotPasswordClick} className="font-medium text-blue-600 hover:text-blue-500"> Forgot your password? </button>
                        </div>
                      </div>

                      <div>
                        <button ref={submitButtonRef} type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          SignIn
                        </button>
                      </div>
                    </form>
                    <CardInfinitProgress show={isProcessing}></CardInfinitProgress>
                  </div>
                </div>
                <div className="py-5">
                  <div className="grid grid-cols-1 gap-1">
                    <div className="credit text-center">
                      ©Copyright. All Right Reserved 2018 - {new Date().getFullYear()}
                      <h5>Kate Raengpetch Law Office Co., Ltd.</h5>
                      <span className="text-blue-600 hover:text-blue-500 pointer" onClick={props.onPrivacyPolicyClick}>Privacy &amp; Policy</span> &nbsp;|&nbsp; <span className="text-blue-600 hover:text-blue-500 pointer" onClick={props.onAboutUsClick}>About us</span> &nbsp;|&nbsp; Web Application {packageJson.version}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


