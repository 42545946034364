import './TextBoxWithValidator.scss'

import { ChangeEvent, HTMLInputTypeAttribute, forwardRef, useEffect, useState } from 'react';

export interface Props {
  label?: string,
  placeHolder?: string,
  pattern?: RegExp,
  value: string,
  equalTo?: string,
  errorMessage?: string,
  type?: HTMLInputTypeAttribute,
  onChange?: (value: ReturnValue) => void
}
const TextBoxWithValidator = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [isError, setIsError] = useState(false);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    let value = event.target.value;
    validate(value, props.equalTo);
  }

  function validate(value: string, equalTo?: string) {
    const isPatternError = props.pattern === undefined ? false : !props.pattern.test(value);
    const isEqualError = equalTo === undefined ? false : value !== equalTo;
    const isError = isPatternError || isEqualError;
    setIsError(isError);
    if (props.onChange) props.onChange({
      value: value,
      errorMessage: isError ? props.errorMessage : undefined
    });
  }

  useEffect(() => {
    validate(props.value, props.equalTo);
  }, [props.equalTo])

  return (
    <div>
      <label htmlFor="input-textbox" className={(isError ? "text-red-500 " : "text-gray-900 ") + "block text-md font-medium"}><span>{props.label}</span>{isError ? <span className="ml-2 mt-0.5 float-right text-sm text-red-600 dark:text-red-500">{props.errorMessage}</span> : ""}</label>
      <input ref={ref} type={props.type ?? 'text'} className={(isError ? "bg-red-100 border border-red-400 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 " : "border-gray-400 text-gray-900 ") + "border rounded-lg block w-full p-2.5"} placeholder={props.placeHolder} value={props.value} onChange={handleChange} />
    </div>
  );
})

export type ReturnValue = {
  value: string,
  errorMessage?: string
}

export default TextBoxWithValidator;
