import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

export type HttpRequestConfig = AxiosRequestConfig;
export type HttpResponse<T> = AxiosResponse<T>;

export async function httpRequest<T>(config: HttpRequestConfig): Promise<{ res: HttpResponse<T> | null, err: any }> {
  config.timeout = config.timeout ?? parseFloat(process.env.API_REQUEST_TIMEOUT ?? '10000');
  let result: { res: AxiosResponse | null, err: any } = { res: null, err: null };
  console.log(`Requesting ${config.method} to ${config.url} with data.`, { data: config.data });
  try {
    result.res = await axios(config);
  } catch (error: any) {
    if (error.response && typeof error.response.data == 'object') {
      result.res = error.response;
    }
    result.err = error;
  }
  if (result.res) {
    const contentType = result.res.headers['content-type'];
    const contentLength = result.res.headers['content-length'];
    if (result.res.data) {
      let data: any = result.res.data;
      if (contentType?.includes('application/json')) {
        console.log(`Receiving response status ${result.res.status} from ${config.url} with content.`, { data: data });
      } else if (data === '') {
        console.log(`Receiving response status ${result.res.status} from ${config.url} with no content.`);
      } else {
        console.log(`Receiving response status ${result.res.status} from ${config.url} with ${contentType} content of ${contentLength} bytes.`);
      }
    } else {
      console.log(`Receiving response status ${result.res.status} from ${config.url} with no content.`);
    }
  } else {
    console.log(`No response from ${config.url} but got status: '${result.err.status}' error: '${result.err.message}'.`);
  }
  return result;
}