import { TableData } from "../components/tables/Basic";
import { camelCaseDeep } from "../helpers/dto";
import { DecisionType, toDecisionTypeString } from "../helpers/business";
import { Person } from "../models/person";
import { lastUpdatePersonByDate, searchPersonByCaseNumber, searchPersonByName, searchPersonByNationalId } from "../services/person";
import { toThaiDateString } from "../helpers/date";

export async function lastUpdatePersonByDateForBasicTable(date: Date): Promise<TableData[][]> {
  const people = await lastUpdatePersonByDate(date);
  return toBasicTable(camelCaseDeep<Person[]>(people));
}

export async function searchPersonByNationalIdForBasicTable(nationalId: string): Promise<TableData[][]> {
  const people = await searchPersonByNationalId(nationalId);
  return toBasicTable(camelCaseDeep<Person[]>(people));
}

export async function searchPersonByNameForBasicTable(firstName: string, lastName: string, middleName?: string): Promise<TableData[][]> {
  const people = await searchPersonByName(firstName, lastName, middleName);
  return toBasicTable(camelCaseDeep<Person[]>(people));
}

export async function searchPersonByCaseNumberForBasicTable(caseNumber: string): Promise<TableData[][]> {
  const people = await searchPersonByCaseNumber(caseNumber);
  return toBasicTable(camelCaseDeep<Person[]>(people));
}

export function toBasicTable(people: Person[]): TableData[][] {
  return people.map((person) => {
    return [
      { display: <span>{person.nationalId}</span> },
      { display: <span>{`${person.prefix}${person.firstName}${person.middleName ? ' ' + person.middleName : ''} ${person.lastName}`}</span> },
      { display: <span>{person.job || '-'}</span> },
      { display: <span>{person.address || '-'}</span> },
      { display: <span>{person.caseNumber}</span> },
      { display: <span>{person.plaintiff}</span> },
      { display: <span>{toDecisionTypeString(person.decisionResult as DecisionType)}</span> },
      { display: <span>{toThaiDateString(person.decisionDate)}</span> },
      { display: <span>{toThaiDateString(person.publishDate)}</span> }
    ];
  })
}
