import { HttpRequestConfig } from "../helpers/http";

class CustomError extends Error {
  isCustom: boolean = true;
}
export class UnhandleError extends CustomError {
  info?: any;
  error: Error;

  name = 'UnhandleError';
  constructor(error: Error, info?: any) {
    super('Unhandle error.');
    this.info = info;
    this.error = error;
  }
}
export class NotImplementedError extends CustomError {
  target: string;
  value: any;
  name = 'NotImplementedError';
  constructor(target: string, value: any) {
    super(`The '${value}' for '${target}' is not implement yet.`);
    this.target = target;
    this.value = value;
  }
}

export class UnhandledValueError extends CustomError {
  target: string;
  value: any;
  name = 'UnhandledValueError';
  constructor(target: string, value: any) {
    super(`Unhandled value '${value}' for '${target}'.`);
    this.target = target;
    this.value = value;
  }
}

type ResponseError = { error: string, errorDescription: string, fields?: [{ condition: string, conitionParam: string, field: string, value: string }] }

export class UnprocessableEntityError extends CustomError {
  config: HttpRequestConfig;
  response: ResponseError
  name = 'UnprocessableEntityError';
  constructor(config: HttpRequestConfig, response: ResponseError) {
    super(response.errorDescription ?? 'Got unprocessable entity error.');
    this.config = config;
    this.response = response
  }
}

export class BadRequestError extends CustomError {
  response: ResponseError;
  config: HttpRequestConfig;
  name = 'BadRequestError';
  constructor(config: HttpRequestConfig, response: ResponseError) {
    super(`Got bad request error.`);
    this.response = response;
    this.config = config;
  }
}

export class BadGatewayError extends CustomError {
  error: Error;
  config: HttpRequestConfig;
  name = 'BadGateWayError';
  constructor(config: HttpRequestConfig, error: Error) {
    super(`Got error while trying '${config.method} to ${config.url}'.\n'${error.message}'\n${error.stack}`);
    this.error = error;
    this.config = config;
  }
}

export class InvalidApiKeyError extends CustomError {
  name = 'InvalidApiKeyError';
  apiKey?: string;
  constructor(apiKey: string) {
    super('Invalid API Key.');
    this.apiKey = apiKey;
  }
}

export class PermissionDeniedError extends CustomError {
  permissions: string[];
  value: any;
  name = 'PermissionDeniedError';
  constructor(permissions: string | string[]) {
    const permissionText = Array.isArray(permissions) ? permissions.join(',') : permissions;
    super(`Permission denied for '${permissions}'.`);
    this.permissions = Array.isArray(permissions) ? permissions : [permissions];
  }
}

export class EnvironmentVariableNotFoundError extends CustomError {
  envarName: string;
  name = 'EnvironmentVariableNotFoundError';
  constructor(envarName: string) {
    super(`Can't find '${envarName}' environment variable.`);
    this.envarName = envarName;
  }
}

export class UserNotFoundOnDatabaseError extends CustomError {
  name = 'UserNotFoundOnDatabaseError';
  username?: string;
  constructor(username: string) {
    super(`Can't find the user on the database.`);
    this.username = username;
  }
}

export class UserPasswordIncorrectError extends CustomError {
  name = 'UserPasswordIncorrectError';
  username?: string;
  constructor(username: string) {
    super(`The user's password is incorrect.`);
    this.username = username;
  }
}

export class UserPasswordExpiredError extends CustomError {
  name = 'UserPasswordExpiredError';
  expiredAt?: Date;
  username?: string;
  constructor(username: string, expiredAt?: Date) {
    super(`The user's password expired.`);
    this.expiredAt = expiredAt;
    this.username = username
  }
}

export class UserInactiveError extends CustomError {
  name = 'UserInactiveError';
  username?: string;
  constructor(username: string) {
    super(`The user status is not active.`);
    this.username = username;
  }
}

export class UserFirstLoginError extends CustomError {
  name = 'UserFirstLoginError';
  username?: string;
  constructor(username: string) {
    super(`The user has been logged in for the first time.`);
    this.username = username
  }
}

export class UserPasswordChangedError extends CustomError {
  name = 'PasswordChangedError';
  username?: string;
  constructor(username: string) {
    super(`The password has been changed.`);
    this.username = username;
  }
}

export class InvalidAccessTokenError extends CustomError {
  name = 'InvalidAccessTokenError';
  accessToken?: string;
  constructor(accessToken: string) {
    super(`Invalid access token.`);
    this.accessToken = accessToken;
  }
}

export class InvalidRefreshTokenError extends CustomError {
  name = 'InvalidRefreshTokenError';
  refreshToken?: string;
  constructor(refreshToken: string) {
    super(`Invalid refresh token.`);
    this.refreshToken = refreshToken;
  }
}

export class UserInvalidCredentialFromAgencyPortalError extends CustomError {
  name = 'UserInvalidCredentialFromAgencyPortalError';
  username?: string;
  constructor(username: string) {
    super(`Invalid credential.`);
    this.username = username;
  }
}

export class NotFoundError extends CustomError {
  name = 'NotFoundError';
  message: string;
  constructor(message?: string) {
    super(message);
    this.message = message ? message : 'Not Found';
  }
}