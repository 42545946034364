import { useState } from "react";
import SignInModal from '../modals/SignIn';
import PrivacyPolicyModal from '../modals/PrivacyPolicy';
import AboutUsModal from '../modals/AboutUs';

import './SignIn.scss'

function SignIn() {
  const [showSignIn, setShowSignIn] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(true);
  const [showAboutUs, setShowAboutUs] = useState(false);

  function openSignIn() {
    setShowAboutUs(false);
    setShowPrivacyPolicy(false);
    setShowSignIn(true);
  }

  function openPrivacyPolicy() {
    setShowSignIn(false);
    setShowAboutUs(false);
    setShowPrivacyPolicy(true);
  }

  function openAboutUs() {
    setShowSignIn(false);
    setShowPrivacyPolicy(false);
    setShowAboutUs(true);
  }

  return (
    <div className="">

      <SignInModal show={showSignIn} onPrivacyPolicyClick={openPrivacyPolicy} onAboutUsClick={openAboutUs}></SignInModal>
      <PrivacyPolicyModal show={showPrivacyPolicy} onAcceptClick={openSignIn}></PrivacyPolicyModal>
      <AboutUsModal show={showAboutUs} onCloseClick={openSignIn}></AboutUsModal>

    </div>
  );
}

export default SignIn;
