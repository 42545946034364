import { JuristicDso } from "../models/juristic";


export async function lastUpdateJuristicByDate(date: Date): Promise<JuristicDso[]> {
  return new Promise((resolve) => {
    return resolve([{
      license_id: '0113530001456',
      name: 'ห้างหุ้นส่วนจำกัด 139 ธุรกิจก่อสร้าง',
      address: 'บ้านเลขที่ 139 หมู่ที่ 5 ถนนสุขสวัสดิ์ ตำบลแหลมฟ้าผ่า อำเภอพระสมุทรเจดีย์ จังหวัดสมุทรปราการ',
      case_number: 'สป.1223',
      plaintiff: 'บริษัท บริหารสินทรัพย์กรุงเทพพาณิชย์ จำกัด',
      decision_result: 'ABSOLUTE_RECEIVERSHIP',
      decision_date: new Date('2016-01-25'),
      publish_date: new Date('2016-07-12'),
    }]);
  });
}
export async function searchJuristicByLicenseId(licenseId: string): Promise<JuristicDso[]> {
  return new Promise((resolve) => {
    return resolve([{
      license_id: licenseId,
      name: 'ห้างหุ้นส่วนจำกัด 139 ธุรกิจก่อสร้าง',
      address: 'บ้านเลขที่ 139 หมู่ที่ 5 ถนนสุขสวัสดิ์ ตำบลแหลมฟ้าผ่า อำเภอพระสมุทรเจดีย์ จังหวัดสมุทรปราการ',
      case_number: 'สป.1223',
      plaintiff: 'บริษัท บริหารสินทรัพย์กรุงเทพพาณิชย์ จำกัด',
      decision_result: 'ABSOLUTE_RECEIVERSHIP',
      decision_date: new Date('2016-01-25'),
      publish_date: new Date('2016-07-12'),
    }]);
  });
}

export async function searchJuristicByName(name: string): Promise<JuristicDso[]> {
  return new Promise((resolve) => {
    return resolve([{
      license_id: '0113530001456',
      name: name,
      address: 'บ้านเลขที่ 139 หมู่ที่ 5 ถนนสุขสวัสดิ์ ตำบลแหลมฟ้าผ่า อำเภอพระสมุทรเจดีย์ จังหวัดสมุทรปราการ',
      case_number: 'สป.1223',
      plaintiff: 'บริษัท บริหารสินทรัพย์กรุงเทพพาณิชย์ จำกัด',
      decision_result: 'ABSOLUTE_RECEIVERSHIP',
      decision_date: new Date('2016-01-25'),
      publish_date: new Date('2016-07-12'),
    }]);
  });
}

export async function searchJuristicByCaseNumber(caseNumber: string): Promise<JuristicDso[]> {
  return new Promise((resolve) => {
    return resolve([{
      license_id: '0113530001456',
      name: 'ห้างหุ้นส่วนจำกัด 139 ธุรกิจก่อสร้าง',
      address: 'บ้านเลขที่ 139 หมู่ที่ 5 ถนนสุขสวัสดิ์ ตำบลแหลมฟ้าผ่า อำเภอพระสมุทรเจดีย์ จังหวัดสมุทรปราการ',
      case_number: caseNumber,
      plaintiff: 'บริษัท บริหารสินทรัพย์กรุงเทพพาณิชย์ จำกัด',
      decision_result: 'ABSOLUTE_RECEIVERSHIP',
      decision_date: new Date('2016-01-25'),
      publish_date: new Date('2016-07-12'),
    }]);
  });
}