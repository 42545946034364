import { SnakeCasedPropertiesDeep } from "type-fest";
import { SignUpUserInfo, User } from "../models/security";
import * as securityService from "../services/security";
import { camelCaseDeep } from "../helpers/dto";

export async function signUp(name: string, phone: string, password: string, oobCode: string): Promise<User> {
  const info: SnakeCasedPropertiesDeep<SignUpUserInfo> = {
    name: name,
    phone: phone,
    password: password,
    oob_code: oobCode,
  };
  const user = await securityService.signUp(info)
  return camelCaseDeep<User>(user);
}

export function isSystemUser(systemRoles: string[]): boolean {
  return securityService.isSystemUser(systemRoles);
}