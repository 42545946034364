
import './Search.scss';

import { Fragment, useRef, useState, MutableRefObject, useEffect } from 'react';
import TwoLevelTabs from '../tabs/TwoLevelTabs';
import BasicTable, { TableHeader, TableData } from "../tables/Basic";
import { verifyNationalId, SearchScope, SearchBy, Scope, SearchPersonBy, SearchJuristicBy } from "../../helpers/business";
import { searchPersonByNationalIdForBasicTable, searchPersonByNameForBasicTable, searchPersonByCaseNumberForBasicTable } from '../../controllers/person';
import { searchJuristicByLicenseIdForBasicTable, searchJuristicByNameForBasicTable, searchJuristicByCaseNumberForBasicTable } from '../../controllers/juristic';
import { displayInputError } from '../../helpers/validator';

function Search() {
  let [searchScope, setSearchScope] = useState<SearchScope>(Scope.PERSON);
  const searchScopeRef = useRef<SearchScope>(Scope.PERSON);
  let [searchBy, setSearchBy] = useState<SearchBy>(SearchPersonBy.NATIONAL_ID);
  const searchByRef = useRef<SearchBy>(SearchPersonBy.NATIONAL_ID);
  const personNationalIdInput = useRef() as MutableRefObject<HTMLInputElement>;
  const personFirstNameInput = useRef() as MutableRefObject<HTMLInputElement>;
  const personMiddleNameInput = useRef() as MutableRefObject<HTMLInputElement>;
  const personLastNameInput = useRef() as MutableRefObject<HTMLInputElement>;
  const personCaseNumberInput = useRef() as MutableRefObject<HTMLInputElement>;
  const juristicLicenseIdInput = useRef() as MutableRefObject<HTMLInputElement>;
  const juristicNameInput = useRef() as MutableRefObject<HTMLInputElement>;
  const juristicCaseNumberInput = useRef() as MutableRefObject<HTMLInputElement>;
  let [tableContent, setTableContent] = useState<{ headers: TableHeader[], data?: TableData[][] } | undefined>(undefined);

  useEffect(() => {
    searchScopeRef.current = searchScope;
  }, [searchScope]);

  useEffect(() => {
    searchByRef.current = searchBy;
  }, [searchBy]);


  let tableHeaderPerson: TableHeader[] = [
    {
      display: <span>เลขที่บัตรประชาชน</span>,
      width: '150px'
    },
    {
      display: <span>ชื่อ-นามสกุล</span>,
      width: '150px'
    },
    {
      display: <span>อาชีพ</span>,
      width: '200px'
    },
    {
      display: <span>ที่อยู่</span>,
      width: '350px'
    },
    {
      display: <span>หมายเลขคดี</span>,
      width: '150px'
    },
    {
      display: <span>โจทก์</span>,
      width: '150px'
    },
    {
      display: <span>ผลการตัดสิน</span>,
      width: '150px'
    },
    {
      display: <span>วันที่ตัดสิน</span>,
      width: '150px'
    },
    {
      display: <span>วันที่ประกาศ</span>,
      width: '150px'
    }
  ];

  const tableHeaderJuristic = [
    {
      display: <span>เลขที่ใบอนุญาต</span>
    },
    {
      display: <span>ชื่อนิติบุคคล</span>
    },
    {
      display: <span>ที่อยู่</span>
    },
    {
      display: <span>หมายเลขคดี</span>
    },
    {
      display: <span>โจทก์</span>
    },
    {
      display: <span>ผลการตัดสิน</span>
    },
    {
      display: <span>วันที่ตัดสิน</span>
    },
    {
      display: <span>วันที่ประกาศ</span>
    }
  ];


  async function search(searchScope: Scope, searchBy: SearchPersonBy | SearchJuristicBy) {
    if (searchScope === Scope.PERSON) {
      if (searchBy === SearchPersonBy.NATIONAL_ID) {
        if (personNationalIdInput?.current && /\d{13}/.test(personNationalIdInput.current.value) && verifyNationalId(personNationalIdInput.current.value)) {
          setTableContent({ headers: tableHeaderPerson, data: await searchPersonByNationalIdForBasicTable(personNationalIdInput.current.value) });
          return;
        }
        displayInputError(personNationalIdInput, 'เลขที่บัตรประชาชนไม่ถูกต้อง');
      } else if (searchBy === SearchPersonBy.NAME) {
        let firstNameOk = true;
        let lastNameOk = true;
        firstNameOk = firstNameOk && personFirstNameInput?.current && personFirstNameInput.current.value.length > 2;
        if (!firstNameOk) displayInputError(personFirstNameInput, 'ชื่อต้นไม่ถูกต้อง');
        lastNameOk = lastNameOk && personLastNameInput?.current && personLastNameInput.current.value.length > 2;
        if (!lastNameOk) displayInputError(personLastNameInput, 'นามสกุลไม่ถูกต้อง');
        if (firstNameOk && lastNameOk) {
          setTableContent({ headers: tableHeaderPerson, data: await searchPersonByNameForBasicTable(personFirstNameInput.current.value, personLastNameInput.current.value, personMiddleNameInput.current.value) });
          return;
        }
      } else if (searchBy === SearchPersonBy.CASE_NUMBER) {
        if (personCaseNumberInput?.current && personCaseNumberInput?.current.value.length > 8) {
          setTableContent({ headers: tableHeaderPerson, data: await searchPersonByCaseNumberForBasicTable(personCaseNumberInput.current.value) });
          return;
        }
        displayInputError(personCaseNumberInput, 'หมายเลขคดีไม่ถูกต้อง');
      }
    } else if (searchScope === Scope.JURISTIC) {
      if (searchBy === SearchJuristicBy.LICENSE_ID) {
        if (juristicLicenseIdInput?.current && /\d{13}/.test(juristicLicenseIdInput.current.value) && verifyNationalId(juristicLicenseIdInput.current.value)) {
          setTableContent({ headers: tableHeaderJuristic, data: await searchJuristicByLicenseIdForBasicTable(juristicLicenseIdInput.current.value) });
          return;
        }
        displayInputError(juristicLicenseIdInput, 'เลขที่ใบอนุญาตไม่ถูกต้อง');
      } else if (searchBy === SearchJuristicBy.NAME) {
        if (juristicNameInput?.current && juristicNameInput.current.value.length > 10) {
          setTableContent({ headers: tableHeaderJuristic, data: await searchJuristicByNameForBasicTable(juristicNameInput.current.value) });
          return;
        }
        displayInputError(juristicNameInput, 'ชื่อนิติบุคคลไม่ถูกต้อง');
      } else if (searchBy === SearchJuristicBy.CASE_NUMBER) {
        if (juristicCaseNumberInput?.current && juristicCaseNumberInput.current.value.length > 5) {
          setTableContent({ headers: tableHeaderJuristic, data: await searchJuristicByCaseNumberForBasicTable(juristicCaseNumberInput.current.value) });
          return;
        }
        displayInputError(juristicCaseNumberInput, 'หมายเลขคดีไม่ถูกต้อง');
      }
    }
  }

  return (
    <Fragment>
      <div><h2 className='text-blue-900 text-3xl mt-10'>ค้นหารายชื่อบุคคลและนิติบุคคลล้มละลาย</h2></div>
      <div className='flex justify-center'>
        <TwoLevelTabs className='w-1/2 mt-5'
          tabs={[
            {
              name: 'ค้นหา:',
              disabled: true
            },
            {
              name: 'บุคคล',
              onSelected: () => { setSearchScope(Scope.PERSON) },
              selected: true,
              subTabs: [
                {
                  name: 'โดย:',
                  disabled: true
                },
                {
                  name: 'เลขที่บัตรประชาชน',
                  onSelected: () => { setSearchBy(SearchPersonBy.NATIONAL_ID); },
                  content: (<form noValidate onSubmit={(e) => { e.preventDefault(); search(searchScopeRef.current, searchByRef.current); }}><div className='grid grid-cols-4 gap-2'>
                    <input ref={personNationalIdInput} type="text" className="person-national-id-input bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-600 block col-span-3 w-full p-2.5" placeholder="เลขที่บัตรประชาชน" required />
                    <button className='text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-md py-2 mr-1 focus:outline-none' type='submit'>ค้นหา</button>
                  </div></form>),
                  selected: true,
                },
                {
                  name: 'ชื่อ-นามสกุล',
                  onSelected: () => { setSearchBy(SearchPersonBy.NAME); },
                  content: (<form noValidate onSubmit={(e) => { e.preventDefault(); search(searchScopeRef.current, searchByRef.current); }}><div className='grid grid-cols-4 gap-2'>
                    <input ref={personFirstNameInput} type="text" className="person-first-name-input bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-600 block col-span-3 w-full p-2.5" placeholder="ชื่อต้น" required />
                    <button className='text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-md py-2 mr-1 focus:outline-none row-span-3' type='submit'>ค้นหา</button>
                    <input ref={personMiddleNameInput} type="text" className="person-middle-name-input bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-600 block col-span-3 w-full p-2.5" placeholder="ชื่อกลาง" />
                    <input ref={personLastNameInput} type="text" className="person-last-name-input bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-600 block col-span-3 w-full p-2.5" placeholder="นามสกุล" required />
                  </div></form>),
                },
                {
                  name: 'เลขที่คดี',
                  onSelected: () => { setSearchBy(SearchPersonBy.CASE_NUMBER); },
                  content: (<form noValidate onSubmit={(e) => { e.preventDefault(); search(searchScopeRef.current, searchByRef.current); }}><div className='grid grid-cols-4 gap-2'>
                    <input ref={personCaseNumberInput} type="text" className="person-case-number-input bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-600 block col-span-3 w-full p-2.5" placeholder="เลขที่คดี" required />
                    <button className='text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-md py-2 mr-1 focus:outline-none' type='submit'>ค้นหา</button>
                  </div></form>)
                },
              ],
            },
            {
              name: 'นิติบุคคล',
              onSelected: () => { setSearchScope(Scope.JURISTIC) },
              subTabs: [
                {
                  name: 'โดย:',
                  disabled: true
                },
                {
                  name: 'เลขที่ใบอนุญาต',
                  onSelected: () => { setSearchBy(SearchJuristicBy.LICENSE_ID); },
                  content: (<form noValidate onSubmit={(e) => { e.preventDefault(); search(searchScopeRef.current, searchByRef.current); }}><div className='grid grid-cols-4 gap-2'>
                    <input ref={juristicLicenseIdInput} type="text" className="juristic-license-id-input bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-600 block col-span-3 w-full p-2.5" placeholder="เลขที่ใบอนุญาต" required />
                    <button className='text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-md py-2 mr-1 focus:outline-none' type='submit'>ค้นหา</button>
                  </div></form>),
                  selected: true
                },
                {
                  name: 'ชื่อนิติบุคคล',
                  onSelected: () => { setSearchBy(SearchJuristicBy.NAME); },
                  content: (<form noValidate onSubmit={(e) => { e.preventDefault(); search(searchScopeRef.current, searchByRef.current); }}><div className='grid grid-cols-4 gap-2'>
                    <input ref={juristicNameInput} type="text" className="juristic-name-input bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-600 block col-span-3 w-full p-2.5" placeholder="ชื่อนิติบุคคล" required />
                    <button className='text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-md py-2 mr-1 focus:outline-none' type='submit'>ค้นหา</button>
                  </div></form>)
                },
                {
                  name: 'เลขที่คดี',
                  onSelected: () => { setSearchBy(SearchJuristicBy.CASE_NUMBER); },
                  content: (<form noValidate onSubmit={(e) => { e.preventDefault(); search(searchScopeRef.current, searchByRef.current); }}><div className='grid grid-cols-4 gap-2'>
                    <input ref={juristicCaseNumberInput} type="text" className="juristic-case-number-input bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-600 block col-span-3 w-full p-2.5" placeholder="เลขที่คดี" required />
                    <button className='text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-md py-2 mr-1 focus:outline-none' type='submit'>ค้นหา</button>
                  </div></form>)
                },
              ]
            }
          ]}
        />
      </div>
      <div className='p-5 flex flex-1 justify-center overflow-auto'>
        <div className='flex h-full w-full'>
          <BasicTable headers={tableContent?.headers} data={tableContent?.data}></BasicTable>
        </div>
      </div>
    </Fragment>
  );

}

export default Search;
