
import logoSm from '../../assets/images/logo_sm.png'
import {
  Bars3Icon,
  AdjustmentsVerticalIcon,
} from '@heroicons/react/24/outline'
import './Landing.scss';

import { getAuth, signOut, ParsedToken } from "firebase/auth";
import { Link } from "react-router-dom";
import PrivacyPolicyModal from '../modals/PrivacyPolicy';
import AboutUsModal from '../modals/AboutUs';
import packageJson from "../../../package.json";
import { ReactElement, useState } from "react";
import { CurrentUserInfo } from '../../models/security';
import { isSystemUser } from "../../controllers/security";

interface Props {
  children: ReactElement;
  user?: CurrentUserInfo | null;
  claims?: ParsedToken | null;
}

function Landing(props: Props) {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);

  function openPrivacyPolicy() {
    setShowAboutUs(false);
    setShowPrivacyPolicy(true);
  }

  function openAboutUs() {
    setShowPrivacyPolicy(false);
    setShowAboutUs(true);
  }

  async function logout() {
    try {
      let auth = getAuth();
      await signOut(auth);
    } catch (error) {
      console.log('logout', error);
    }
  }

  return (
    <div className="flex h-screen flex-col">
      <nav className="nav px-2 md:px-4 py-2.5">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-full">
          <a href="/" className="flex items-center">
            <img src={logoSm} className="mr-3 h-6 block" alt="Kate Raengpetch Law Office" />
          </a>
          <div className="flex items-center md:order-2">
            <li className="group relative dropdown list-none text-white hover:bg-gray-800 py-2 focus:ring-4 focus:ring-blue-300 font-medium rounded-md mr-1 focus:outline-none block lg:hidden">
              <span className="mx-4 px-10">
                <Bars3Icon className="inline h-4 w-4" aria-hidden="true" />
                <span className="ml-1 whitespace-nowrap">Menu</span>
              </span>
              <div className="group-hover:block hidden dropdown-menu bg-gray-800  px-3 py-2 text-white absolute h-auto rounded-md">
                <ul className="mt-2 rounded-md">
                  <li className="hover:bg-gray-700 rounded-md cursor-pointer"><Link to="/search" className="block py-2">ค้นหารายชื่อบุคคลและนิติบุคคลล้มละลาย</Link></li>
                  <li className="hover:bg-gray-700 rounded-md cursor-pointer"><Link to="/update" className="block py-2">รายชื่อบุคคลและนิติบุคคลล้มละลายอัพเดตล่าสุด</Link></li>
                  {
                    isSystemUser(props.claims?.system_roles) ? (<li className="hover:bg-gray-700 rounded-md cursor-pointer"><Link to="/back-office" className="block py-2">Backoffice</Link></li>) : null
                  }
                </ul>
              </div>
            </li>
            <li className="group relative dropdown list-none text-white hover:bg-white hover:text-gray-900 border-gray-200 py-2 z-50 focus:ring-4 focus:ring-blue-300 font-medium rounded-md mr-1 focus:outline-none">
              <span className="mx-4">
                <AdjustmentsVerticalIcon className="inline h-4 w-4" />
                <span className="ml-1 whitespace-nowrap">{props.user?.displayName || props.user?.email}</span>
              </span>
              <div className="group-hover:block hidden dropdown-menu bg-white px-3 py-2 text-gray-900 border-gray-200 absolute w-full h-auto rounded-md">
                <ul className="mt-2 rounded-md">
                  <li className="hover:hover:bg-gray-100 rounded-md cursor-pointer"><span onClick={logout} className="block py-2">Logout</span></li>
                </ul>
              </div>
            </li>
          </div>
          <div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1">
            <ul className="flex flex-col mt-4 font-medium md:flex-row md:space-x-8 md:mt-0">
              <li>
                <Link to="/search" className="block py-2 pr-4 pl-3 text-white border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-300 md:hover:underline md:p-0">ค้นหารายชื่อบุคคลและนิติบุคคลล้มละลาย</Link>
              </li>
              <li>
                <Link to="/update" className="block py-2 pr-4 pl-3 text-white border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-300 md:hover:underline md:p-0">รายชื่อบุคคลและนิติบุคคลล้มละลายอัพเดตล่าสุด</Link>
              </li>
              {
                isSystemUser(props.claims?.system_roles) ? (
                  <li>
                    <Link to="/back-office" className="block py-2 pr-4 pl-3 text-white border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-300 md:hover:underline md:p-0">Backoffice</Link>
                  </li>) : null
              }

            </ul>
          </div>
        </div>
      </nav>
      <div className="flex-container">
        {props.children}
      </div>
      <div className="">
        <div>©Copyright. All Right Reserved 2018 - {(new Date()).getFullYear()}</div>
        <div>Kate Raengpetch Law Office Co., Ltd.</div>
        <div><span className="text-blue-600 hover:text-blue-500 pointer" onClick={openPrivacyPolicy}>Privacy &amp; Policy</span> &nbsp;|&nbsp; <span className="text-blue-600 hover:text-blue-500 pointer" onClick={openAboutUs}>About us</span> &nbsp;|&nbsp; Web Application {packageJson.version}</div>
      </div>
      <PrivacyPolicyModal show={showPrivacyPolicy} onAcceptClick={() => { setShowPrivacyPolicy(false) }}></PrivacyPolicyModal>
      <AboutUsModal show={showAboutUs} onCloseClick={() => { setShowAboutUs(false) }}></AboutUsModal>
    </div>
  );
}

export default Landing;
