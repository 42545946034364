import './Basic.scss';

import { ReactElement } from 'react'

export interface TableHeader {
  display: ReactElement;
  width?: string;
}

export interface TableData {
  display: ReactElement;
}

interface Props {
  className?: string;
  headers: TableHeader[] | undefined;
  data?: TableData[][] | null | undefined;
}

export default function BasicTable(props: Props) {

  return props.headers === undefined || props.data === undefined ? <div></div> : (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className={`basic-table border h-full overflow-x-auto ${props.className || ''}`}>
        <thead className={`basic-table-header bg-blue-700 text-white`}>
          <tr>
            {
              props.headers.map((header, i) => {
                return (<th className="align-top" style={{ width: header.width }} key={i}>{header.display}</th>);
              })
            }
            <th style={{ width: '10px' }}></th>
          </tr>
        </thead>
        <tbody className={`basic-table-body overflow-y-auto`} style={{ gridTemplateColumns: props.headers.map((header) => header.width ? header.width : 'auto').join(' ') }}>
          {
            props.data ? (
              props.data.map((row, i) => { return <tr key={i}>{row.map((col, i) => { return <td key={i} className="align-top" style={{ width: props.headers![i].width }}>{col?.display}</td> })}</tr> })) : (<tr><td>ไม่พบข้อมูล</td></tr>)
          }
        </tbody>
      </table>
    </div >
  )
}