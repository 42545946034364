import { CamelCasedPropertiesDeep, SnakeCasedPropertiesDeep } from "type-fest";
import { isArray, isEmpty, isObject, mapKeys, mapValues, cloneDeep } from "lodash";
import { camelCase, snakeCase } from "change-case";


export function snakeCaseDeep<T>(source: CamelCasedPropertiesDeep<T>): T {
  const thing = cloneDeep(source);

  if (
    isEmpty(thing) ||
    (!isObject(thing) && !isArray(thing))
  ) {
    return thing as T;
  }

  if (isArray(thing)) {
    const arr = thing;
    return arr.map(el => snakeCaseDeep(el)) as unknown as T
  }

  // thing can be only not empty object here
  const objWithMappedKeys = mapKeys(thing, (value, key) => snakeCase(key));
  const objWithMappedValues = mapValues(objWithMappedKeys, value => snakeCaseDeep(value as CamelCasedPropertiesDeep<T>));

  return objWithMappedValues as unknown as T;
}

export function camelCaseDeep<T>(source: SnakeCasedPropertiesDeep<T>): T {
  const thing = cloneDeep(source);

  if (
    isEmpty(thing) ||
    (!isObject(thing) && !isArray(thing))
  ) {
    return thing as T;
  }

  if (isArray(thing)) {
    const arr = thing;
    return arr.map(el => camelCaseDeep(el)) as unknown as T
  }

  // thing can be only not empty object here
  const objWithMappedKeys = mapKeys(thing, (value, key) => camelCase(key));
  const objWithMappedValues = mapValues(objWithMappedKeys, value => camelCaseDeep(value as SnakeCasedPropertiesDeep<T>));

  return objWithMappedValues as unknown as T;
}