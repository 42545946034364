export enum Scope {
  PERSON = 'PERSON',
  JURISTIC = 'JURISTIC'
}

export type SearchScope = Scope.PERSON | Scope.JURISTIC;

export enum SearchPersonBy {
  NATIONAL_ID = 'NATIONAL_ID',
  NAME = 'NAME',
  CASE_NUMBER = 'CASE_NUMBER'
}

export enum SearchJuristicBy {
  LICENSE_ID = 'LICENSE_ID',
  NAME = 'NAME',
  CASE_NUMBER = 'CASE_NUMBER'
}

export type SearchBy = SearchPersonBy.NATIONAL_ID | SearchPersonBy.NAME | SearchPersonBy.CASE_NUMBER | SearchJuristicBy.LICENSE_ID | SearchJuristicBy.NAME | SearchJuristicBy.CASE_NUMBER;

export enum DecisionType {
  ABSOLUTE_RECEIVERSHIP = 'ABSOLUTE_RECEIVERSHIP'
}

export function toDecisionTypeString(decisionType: DecisionType) {
  switch (decisionType) {
    case DecisionType.ABSOLUTE_RECEIVERSHIP:
      return 'คําสั่งพิทักษ์ทรัพย์เด็ดขาด';
    default:
      return '-';
  }
}

export function verifyNationalId(nationalId: string): boolean {
  const digitArray = nationalId.split('').reverse();
  const firstStep = digitArray.map(val => parseInt(val)).reduce((preVal, currVal, i) => { return preVal + (i === 0 ? 0 : currVal * (i + 1)); }, 0);
  const secondStep = firstStep % 11;
  const thirdStep = 11 - secondStep;
  return digitArray[0].toString() === thirdStep.toString();
}