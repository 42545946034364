import './TwoLevelTabs.scss';

import { ReactElement, useState } from 'react'

interface ITab {
  name: string;
  selected?: boolean;
  disabled?: boolean;
  onSelected?: () => void;
  subTabs?: ISubTab[];
}

interface ISubTab {
  name: string;
  content?: ReactElement;
  selected?: boolean;
  disabled?: boolean;
  onSelected?: () => void;
}

interface Props {
  tabs: ITab[];
  className?: string;
}

export default function TwoLevelTabs(props: Props) {
  let [tabs, setTabs] = useState(props.tabs)
  let [subTabs, setSubTabs] = useState(tabs.find((tab) => { return tab.selected })?.subTabs)

  function selectTab(tabIndex: number) {
    let ts = [...tabs];
    ts.map((tab, i) => {
      if (i === tabIndex) {
        tab.selected = true;
        return tab;
      }
      tab.selected = false;
      return tab;
    });
    setTabs(ts);
    setSubTabs(tabs.find((tab) => { return tab.selected })?.subTabs);
  }

  function selectSubTab(tabIndex: number) {
    if (subTabs) {
      let ts = [...subTabs];
      ts.map((tab, i) => {
        if (i === tabIndex) {
          tab.selected = true;
          return tab;
        }
        tab.selected = false;
        return tab;
      });
      setSubTabs(ts);
    }
  }

  return (
    <div className={props.className}>
      <div className="flex justify-center">
        <ul className="flex text-lg font-medium text-center text-gray-500">
          {tabs.map((tab, i) => {
            return tab.selected ?
              (<li key={i.toString()} className="mr-2">
                <span className="inline-block px-4 py-3 text-blue-900 underline rounded-lg" aria-current="page">{tab.name}</span>
              </li>)
              : tab.disabled ?
                (<li key={i.toString()}>
                  <span className="inline-block px-4 py-3 text-gray-400 dark:text-gray-500">{tab.name}</span>
                </li>)
                :
                (<li key={i.toString()} className="mr-2">
                  <span onClick={() => {
                    selectTab(i);
                    if (tab.onSelected) tab.onSelected();
                    const onSelectedSubTab = tab.subTabs?.find((tab) => { return tab.selected });
                    if (onSelectedSubTab?.onSelected) onSelectedSubTab.onSelected();
                  }} className="inline-block cursor-pointer px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100" aria-current="page">{tab.name}</span>
                </li>);
          })}
        </ul>
        <ul className="flex text-lg font-medium text-center text-gray-500">
          {subTabs?.map((tab, i) => {
            return tab.selected ?
              (<li key={i.toString()} className="mr-2">
                <span className="inline-block px-4 py-3 text-blue-900 underline rounded-lg" aria-current="page">{tab.name}</span>
              </li>)
              : tab.disabled ?
                (<li key={i.toString()}>
                  <span className="inline-block px-4 py-3 text-gray-400 dark:text-gray-500">{tab.name}</span>
                </li>)
                :
                (<li key={i.toString()} className="mr-2">
                  <span onClick={() => { selectSubTab(i); if (tab.onSelected) tab.onSelected(); }} className="inline-block cursor-pointer px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100" aria-current="page">{tab.name}</span>
                </li>);
          })}
        </ul>

      </div>
      <div className="col-span-2 text-lg">
        {subTabs?.map((tab, i) => {
          return tab.selected ?
            (<div key={i.toString()} className="" role="tabpanel">
              {tab.content}
            </div>)
            :
            (<div key={i.toString()} className="hidden" role="tabpanel">
              {tab.content}
            </div>);
        })}
      </div>
    </div>
  )
}