export function isDate(dateStr: string) {
  return !isNaN(new Date(dateStr).getDate());
}

export function dateDiff(startDate: Date, endDate: Date) {
  let diffTime = endDate.getTime() - startDate.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays
}

export function toThaiDateString(date: Date) {
  const monthTh = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
  const yearTh = date.getFullYear() + 543;
  return `${date.getDate()} ${monthTh[date.getMonth() + 1]} ${yearTh}`;
}

export function toSecondsTimestamp(date: Date) {
  return Math.floor(date.getTime() / 1000);
}

export function toDateNumberString(date: Date) {
  return `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;
}