import { SnakeCasedPropertiesDeep } from "type-fest";
import { camelCaseDeep, snakeCaseDeep } from "../helpers/dto";
import { httpRequest, HttpRequestConfig } from "../helpers/http";
import { SignUpUserInfo, User } from "../models/security";
import { BadGatewayError, BadRequestError, UnhandleError, UnprocessableEntityError } from "../errors/custom";

export async function signUp(info: SnakeCasedPropertiesDeep<SignUpUserInfo>): Promise<SnakeCasedPropertiesDeep<User>> {
  let config: HttpRequestConfig = {
    method: 'POST',
    url: process.env.REACT_APP_SIGNUP_API,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    },
    data: info,
  };
  const { res, err } = await httpRequest<any>(config);
  if (res && !err) {
    return res.data as SnakeCasedPropertiesDeep<User>;
  } else if (res && err) {
    if (res.data.error.startsWith('E422')) {
      throw new UnprocessableEntityError(config, res.data);
    } else if (res.data.error.startsWith('E400')) {
      throw new BadRequestError(config, res.data);
    }
    throw new UnhandleError(err);
  } else {
    throw new BadGatewayError(config, err);
  }
}

export function isSystemUser(systemRoles: string[]): boolean {
  if (!systemRoles) {
    return false;
  }
  return systemRoles.reduce((sum, role) => {
    if (role.startsWith('SYSTEM')) {
      return sum || true;
    }
    return sum;
  }, false);
}