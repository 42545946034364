import { toast } from 'react-toastify';
import { MutableRefObject } from "react";


export function displayInputError(input: MutableRefObject<HTMLInputElement>, errorText: string) {
  toast.error(errorText);
  input.current.focus();
  input.current.select();
  input.current.classList.add('bg-red-200');
  setTimeout(() => {
    if (input.current) input.current.classList.remove('bg-red-200');
  }, 6000);
}