import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { useEffect, useRef, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AdminAuth from "./components/security/AdminAuth";
import Landing from "./components/pages/Landing";
import SignIn from "./components/pages/SignIn";
import SignUp from "./components/pages/SignUp";
import BackOffice from "./components/pages/BackOffice";
import Search from './components/pages/Search';
import Update from './components/pages/Update';
import { getAuth, ParsedToken } from "firebase/auth";
import CardInfinit from "./components/progress/CardInfinit";
import UserAuth from './components/security/UserAuth';
import AllAuth from './components/security/AllAuth';
import { CurrentUserInfo } from "./models/security";
import { isSystemUser } from "./controllers/security";

export default function App() {
  const [currentUserInfo, setCurrentUserInfo] = useState<CurrentUserInfo | null | undefined>(undefined);
  const [currentUserClaims, setCurrentUserClaims] = useState<ParsedToken | undefined>(undefined);
  const userRef = useRef<CurrentUserInfo | null>(null);
  const auth = getAuth();

  useEffect(() => {
    let unsubscribe = auth.onAuthStateChanged(
      async (user) => {
        setCurrentUserInfo(user ? { displayName: user?.displayName, email: user?.email } : null);
        setCurrentUserClaims(user ? (await user.getIdTokenResult()).claims : undefined);
      }
    );
    return unsubscribe;
  }, [auth]);

  return currentUserInfo === undefined ? <CardInfinit message={'Checking your permission..'} show={true} /> : (
    <div className="App">
      <Routes>
        <Route path="/" element={
          <AllAuth
            user={currentUserInfo}
            claims={currentUserClaims}
            adminChild={<Navigate to="/back-office" />}
            userChild={<Navigate to="/search" />}
            unauthorizedChild={<Navigate to="/signin" />}
            userRef={userRef}
            notify={() => { return true; }}
          ></AllAuth>
        } />
        <Route path="/signin" element={
          <AllAuth
            user={currentUserInfo}
            claims={currentUserClaims}
            adminChild={<Navigate to="/back-office" />}
            userChild={<Navigate to="/search" />}
            unauthorizedChild={<SignIn />}
            userRef={userRef}
            notify={(() => {
              if (userRef.current === null && !!currentUserInfo) {
                return true;
              } else if (window.location.pathname !== '/signin') {
                return true;
              }
              return false;
            })}
          ></AllAuth>
        } />
        <Route path="/search" element={
          <UserAuth
            user={currentUserInfo}
            userChild={<Landing user={currentUserInfo} claims={currentUserClaims}><Search /></Landing>}
            unauthorizedChild={<Navigate to="/signin" />}
            userRef={userRef}
            notify={() => {
              return (userRef.current === null && !!currentUserInfo) || (userRef.current === null && !currentUserInfo) || (!!userRef.current && !currentUserInfo);
            }}
          ></UserAuth>
        } />
        <Route path="/update" element={
          <UserAuth
            user={currentUserInfo}
            userChild={<Landing user={currentUserInfo} claims={currentUserClaims}><Update /></Landing>}
            unauthorizedChild={<Navigate to="/signin" />}
            userRef={userRef}
            notify={() => {
              return (userRef.current === null && !!currentUserInfo) || (userRef.current === null && !currentUserInfo) || (!!userRef.current && !currentUserInfo);
            }}
          ></UserAuth>
        } />
        <Route path="/back-office" element={
          <AdminAuth
            user={currentUserInfo}
            claims={currentUserClaims}
            adminChild={<Landing user={currentUserInfo} claims={currentUserClaims}><BackOffice /></Landing>}
            userChild={<Navigate to="/search" />}
            unauthorizedChild={<Navigate to="/signin" />}
            userRef={userRef}
            notify={() => {
              return !isSystemUser(currentUserClaims?.system_roles) || (userRef.current === null && !!currentUserInfo) || (userRef.current === null && !currentUserInfo) || (!!userRef.current && !currentUserInfo);
            }}
          ></AdminAuth>

        } />
        <Route path="/redeem/signup" element={
          <SignUp />
        } />
      </Routes>
      <ToastContainer position="bottom-right" />
    </div>
  );
}
