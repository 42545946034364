
import './Update.scss';

import { Fragment, useState, useEffect } from 'react';
import Basic from '../tabs/Basic';
import BasicTable, { TableHeader, TableData } from "../tables/Basic";
import { SearchScope, Scope } from "../../helpers/business";
import { lastUpdatePersonByDateForBasicTable } from '../../controllers/person';
import { lastUpdateJuristicByDateForBasicTable } from '../../controllers/juristic';

function Update() {
  let [searchScope, setSearchScope] = useState<SearchScope>(Scope.PERSON);
  let [tableContent, setTableContent] = useState<{ headers: TableHeader[], data?: TableData[][] } | undefined>(undefined);

  const personTableHeader: TableHeader[] = [
    {
      display: <span>เลขที่บัตรประชาชน</span>,
      width: '150px'
    },
    {
      display: <span>ชื่อ-นามสกุล</span>,
      width: '150px'
    },
    {
      display: <span>อาชีพ</span>,
      width: '200px'
    },
    {
      display: <span>ที่อยู่</span>,
      width: '350px'
    },
    {
      display: <span>หมายเลขคดี</span>,
      width: '150px'
    },
    {
      display: <span>โจทก์</span>,
      width: '150px'
    },
    {
      display: <span>ผลการตัดสิน</span>,
      width: '150px'
    },
    {
      display: <span>วันที่ตัดสิน</span>,
      width: '150px'
    },
    {
      display: <span>วันที่ประกาศ</span>,
      width: '150px'
    }
  ];

  const juristicTableHeader = [
    {
      display: <span>เลขที่ใบอนุญาต</span>
    },
    {
      display: <span>ชื่อนิติบุคคล</span>
    },
    {
      display: <span>ที่อยู่</span>
    },
    {
      display: <span>หมายเลขคดี</span>
    },
    {
      display: <span>โจทก์</span>
    },
    {
      display: <span>ผลการตัดสิน</span>
    },
    {
      display: <span>วันที่ตัดสิน</span>
    },
    {
      display: <span>วันที่ประกาศ</span>
    }
  ];

  useEffect(() => {
    (async (searchScope: Scope) => {
      if (searchScope === Scope.PERSON) {
        setTableContent({ headers: personTableHeader, data: await lastUpdatePersonByDateForBasicTable(new Date()) });
      } else if (searchScope === Scope.JURISTIC) {
        setTableContent({ headers: juristicTableHeader, data: await lastUpdateJuristicByDateForBasicTable(new Date()) });
      }
    })(searchScope);
  }, [searchScope]);

  return (
    <Fragment>
      <div><h2 className='text-blue-900 text-3xl mt-10'>รายชื่อบุคคลและนิติบุคคลล้มละลายอัพเดตล่าสุด</h2></div>
      <div className='flex justify-center'>
        <Basic className='w-1/2 mt-5'
          tabs={[
            {
              name: 'บุคคล',
              onSelected: () => { setSearchScope(Scope.PERSON); },
              selected: true
            },
            {
              name: 'นิติบุคคล',
              onSelected: () => { setSearchScope(Scope.JURISTIC); }
            },
          ]}
        />
      </div>
      <div className='p-5 flex flex-1 justify-center overflow-auto'>
        <div className='flex h-full w-full'>
          <BasicTable headers={tableContent?.headers} data={tableContent?.data}></BasicTable>
        </div>
      </div>
    </Fragment>
  );

}

export default Update;
