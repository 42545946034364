import './Tenant.scss';
import buildingPlaceholder from '../../assets/images/building.png'
import {
  MagnifyingGlassIcon,
  ChevronDownIcon
} from '@heroicons/react/24/outline'

function TenantPanel() {

  return (
    <div>
      <div className="relative">
        <form>
          <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <MagnifyingGlassIcon className="inline h-4 w-4 -mt-1" />
            </div>
            <input type="search" id="search" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search" required />
            <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">ค้นหา</button>
          </div>
        </form>
      </div>
      <div className='text-left mt-4'>
        <a href="#" className="flex flex-col bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 w-96 h-40">
          <img className="object-cover w-40 rounded-t-lg md:h-full md:rounded-none md:rounded-l-lg" src={buildingPlaceholder} alt="" />
          <div className="flex flex-col justify-between p-4 leading-normal">
            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">Noteworthy technology acquisitions 2021</h5>
            <div className='grid grid-cols-2 gap-1'>
              <a href="#" className="inline items-center w-20 px-3 py-2 font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">
                Status
                <ChevronDownIcon className="inline h-4 w-4" />
              </a>
              <a href="#" className="inline items-center w-20 px-3 py-2 font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">
                Settings
              </a>
            </div>
          </div>
        </a>

      </div>
    </div>
  );

}

export default TenantPanel;
