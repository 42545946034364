import { useState } from "react";
import SignUpModal from '../modals/SignUp';
import PrivacyPolicyModal from '../modals/PrivacyPolicy';
import AboutUsModal from '../modals/AboutUs';

import './SignUp.scss'

function Signup() {
  const [showLogin, setShowLogin] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(true);
  const [showAboutUs, setShowAboutUs] = useState(false);

  function openLogin() {
    setShowAboutUs(false);
    setShowPrivacyPolicy(false);
    setShowLogin(true);
  }

  function openPrivacyPolicy() {
    setShowLogin(false);
    setShowAboutUs(false);
    setShowPrivacyPolicy(true);
  }

  function openAboutUs() {
    setShowLogin(false);
    setShowPrivacyPolicy(false);
    setShowAboutUs(true);
  }

  return (
    <div className="">

      <SignUpModal show={showLogin} onPrivacyPolicyClick={openPrivacyPolicy} onAboutUsClick={openAboutUs}></SignUpModal>
      <PrivacyPolicyModal show={showPrivacyPolicy} onAcceptClick={openLogin}></PrivacyPolicyModal>
      <AboutUsModal show={showAboutUs} onCloseClick={openLogin}></AboutUsModal>

    </div>
  );
}

export default Signup;
