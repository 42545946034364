import './PrivacyPolicy.scss'
import './Backdrop.scss';

import { Fragment, useRef, MutableRefObject, FocusEvent } from 'react'
import { Dialog, Transition } from '@headlessui/react'


interface Props {
  show: boolean;
  onCloseClick?: () => void;
}

export default function TncModal(props: Props) {
  const closeButton = useRef() as MutableRefObject<HTMLButtonElement>;

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => { }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 unauthorized-backdrop transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left transition-all sm:my-8 sm:mx-8 sm:max-w-6xl" onFocus={(event: FocusEvent<HTMLDivElement>) => { closeButton.current.focus() }}>
                <div className="bg-white">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        <div className="items-start p-4 rounded-t border-b text-center">
                          <h2 className="text-xl font-semibold text-gray-900">
                            เกี่ยวกับเรา
                          </h2>
                          <div>
                            สำนักงาน เกตุ แรงเพ็ชร ทนายความ
                            <br />
                            <span className="text-blue-600">kateraengpetch.com</span>
                            <br />
                            ระบบสืบค้นรายชื่อบุคคลล้มละลาย<br />
                          </div>
                        </div>
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="relative bg-white rounded-lg shadow">
                          <div className="p-6 text-left">
                            <p className="indent-7 text-justify">
                              สำนักงาน เกตุ แรงเพ็ชร ทนายความเป็นที่รู้จักในแวดวงสถาบันการเงินมาเป็นเวลาหลายสิบปี ในฐานะผู้รวบรวม
                              ข้อมูลบุคคลล้มละลาย และเมื่อมาถึงยุคปัจจุบันอันเป็นยุคแห่งข้อมูลข่าวสาร สำนักงานฯตระหนักถึงความรวดเร็วในการ
                              เข้าถึงข้อมูลเพื่อปกป้องผลประโยชน์ทางธุรกิจภายใต้การปฏิบัติตามกฎหมาย สำนักงานฯจึงนำข้อมูลที่เก็บรวบรวมจากอดีต
                              ถึงปัจจุบันมาต่อยอด พัฒนาเป็นเว็บไซต์อันเป็นระบบสืบค้นรายชื่อบุคคลล้มละลายที่ใช้งานง่ายและทราบผลการสืบค้น
                              ภายในไม่กี่วินาที ซึ่งผลของการสืบค้นในเว็บไซต์นี้จะช่วยให้ผู้ใช้งานทราบว่าบุคคลที่ผู้ใช้งานต้องการค้นหานั้นตกเป็นบุคคล
                              ผู้ถูกจำกัดความสามารถตามพระราชบัญญัติล้มละลายหรือไม่ (คือต้องห้ามมิให้กระทำการใดๆ เกี่ยวกับทรัพย์สิน หรือ
                              กิจการของตน เว้นแต่จะได้กระทำการตามคำสั่งหรือความเห็นชอบของศาล เจ้าพนักงานพิทักษ์ทรัพย์ ผู้จัดการทรัพย์
                              หรือที่ประชุมเจ้าหนี้ ตามที่บัญญัติไว้ในพระราชบัญญัติล้มละลาย) เมื่อผู้ใช้งานมีโอกาสทราบความสามารถของบุคคล
                              ที่ผู้ใช้งานเป็นหรือกำลังจะเป็นคู่ค้าทางธุรกิจ/คู่สัญญาแล้ว นั่นคือโอกาสในการป้องกันความเสียหายอันเกิดจากการ
                              ทำนิติกรรมที่มีวัตถุประสงค์ขัดต่อกฎหมาย มีผลเป็นโมฆะตามประมวลกฎหมายแพ่งและพาณิชย์
                            </p>
                            <h4 className="mt-5 underline font-semibold text-gray-900">ติดต่อเรา</h4>
                            <table>
                              <tbody className="overflow-hidden">
                                <tr>
                                  <th className="w-2">โทรศัพท์</th><td className="w-14">0-2120-6485, 090-969-5008, 094-791-1644</td>
                                </tr>
                                <tr>
                                  <th className="w-2">อีเมลล์</th><td className="w-14"><a href="mailto:kateraengpetchlawoffice@gmail.com">kateraengpetchlawoffice@gmail.com</a></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="center-content items-center p-6 space-x-2 rounded-b border-t border-gray-200 text-center">
                            <button ref={closeButton} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={props.onCloseClick}>ปิด</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}