import { TableData } from "../components/tables/Basic";
import { camelCaseDeep } from "../helpers/dto";
import { DecisionType, toDecisionTypeString } from "../helpers/business";
import { Juristic } from "../models/juristic";
import { lastUpdateJuristicByDate, searchJuristicByLicenseId, searchJuristicByName, searchJuristicByCaseNumber } from "../services/juristic";
import { toThaiDateString } from "../helpers/date";


export async function lastUpdateJuristicByDateForBasicTable(date: Date): Promise<TableData[][]> {
  const people = await lastUpdateJuristicByDate(date);
  return toBasicTable(camelCaseDeep<Juristic[]>(people));
}

export async function searchJuristicByLicenseIdForBasicTable(licenseId: string): Promise<TableData[][]> {
  const people = await searchJuristicByLicenseId(licenseId);
  return toBasicTable(camelCaseDeep<Juristic[]>(people));
}

export async function searchJuristicByNameForBasicTable(name: string): Promise<TableData[][]> {
  const people = await searchJuristicByName(name);
  return toBasicTable(camelCaseDeep<Juristic[]>(people));
}

export async function searchJuristicByCaseNumberForBasicTable(caseNumber: string): Promise<TableData[][]> {
  const people = await searchJuristicByCaseNumber(caseNumber);
  return toBasicTable(camelCaseDeep<Juristic[]>(people));
}

export function toBasicTable(people: Juristic[]): TableData[][] {
  return people.map((juristic) => {
    return [
      { display: <span>{juristic.licenseId}</span> },
      { display: <span>{juristic.name}</span> },
      { display: <span>{juristic.address || '-'}</span> },
      { display: <span>{juristic.caseNumber}</span> },
      { display: <span>{juristic.plaintiff}</span> },
      { display: <span>{toDecisionTypeString(juristic.decisionResult as DecisionType)}</span> },
      { display: <span>{toThaiDateString(juristic.decisionDate)}</span> },
      { display: <span>{toThaiDateString(juristic.publishDate)}</span> }
    ];
  })
}
