import './PrivacyPolicy.scss'
import './Backdrop.scss';

import { Fragment, useRef, MutableRefObject, FocusEvent } from 'react'
import { Dialog, Transition } from '@headlessui/react'


interface Props {
  show: boolean;
  onAcceptClick?: () => void;
}

export default function TncModal(props: Props) {
  const acceptButton = useRef() as MutableRefObject<HTMLButtonElement>;

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => { }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 unauthorized-backdrop transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left transition-all sm:my-8 sm:mx-8 sm:max-w-6xl" onFocus={(event: FocusEvent<HTMLDivElement>) => { acceptButton.current.focus() }}>
                <div className="bg-white">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        <div className="items-start p-4 rounded-t border-b text-center">
                          <h2 className="text-xl font-semibold text-gray-900">
                            ข้อตกลงการใช้บริการ
                          </h2>
                          <div>
                            <span className="text-blue-600">kateraengpetch.com</span> ขอขอบพระคุณผู้ใช้บริการทุกท่าน<br />
                            ที่เลือกใช้บริการระบบสืบค้นรายชื่อบุคคลล้มละลายผ่านเว็บไซต์<br /> <a tabIndex={2} className="text-blue-600 hover:text-blue-500" href="www.kateraengpetch.com">www.kateraengpetch.com</a>
                          </div>
                        </div>
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="relative bg-white rounded-lg shadow">
                          <div className="p-6 text-left">
                            <h4>บทนำ</h4>
                            <p className="indent-7 text-justify">
                              สำนักงาน เกตุ แรงเพ็ชร ทนายความ ขอแจ้งข้อตกลงในการใช้บริการเว็บไซต์ www.kateraengpetch.com เป็นเว็บไซต์
                              ของสำนักงาน เกตุ แรงเพ็ชร ทนายความ สำนักงานฯขอสงวนสิทธิ์ในการให้บริการกับลูกค้าที่เป็นสมาชิกรับข้อมูลข่าว
                              คดีล้มละลายของสำนักงาน เกตุ แรงเพ็ชร ทนายความที่ยอมรับเงื่อนไขที่กำหนดไว้ในเว็บไซต์นี้เท่านั้น สำนักงานฯจัดทำ
                              เว็บไซต์เพื่อให้บริการสืบค้นรายชื่อบุคคลล้มละลายอันเป็นการช่วยให้ผู้ใช้งานทราบว่าบุคคลที่ผู้ใช้งานต้องการสืบค้นนั้น
                              ตกเป็นบุคคลผู้ถูกจำกัดความสามารถตามพระราชบัญญัติล้มละลายหรือไม่ โดยเป็นข้อมูลสาธารณะที่กฎหมายให้เปิดเผยได้ตามพระราชบัญญัติประกอบธุรกิจข้อมูลเครดิต พ.ศ.2545 สำนักงานฯขอสงวนสิทธิ์ในการเปลี่ยนแปลง
                              เงื่อนไขการให้บริการนี้ตามความเหมาะสม
                            </p>
                            <h4>ลิขสิทธิ์และเครื่องหมายการค้า</h4>
                            <p className="indent-7 text-justify">
                              สำนักงานฯเป็นเจ้าของลิขสิทธิ์ในข้อความ การแสดงผล และรูปแบบการนำเสนอตามที่ปรากฏในเว็บไซต์ทั้งหมด ยกเว้น
                              มีการระบุอย่างชัดเจนเป็นอื่น ข้อมูลที่เว็บไซต์นี้แสดงผลให้แก่ผู้ใช้ได้รับการคุ้มครองโดยกฎหมายลิขสิทธิ์,กฎหมายเครื่องหมาย
                              การค้าและกฏหมายอื่นที่เกี่ยวข้องกับการคุ้มครองทรัพย์สินทางปัญญา ผู้ใช้สามารถใช้ได้สำหรับวัตถุประสงค์ส่วนตัว
                              ในระดับหนึ่งภายในขอบเขตซึ่งกฏหมายกำหนด กรณีที่ใช้เกินขอบเขตดังกล่าว (รวมถึงการทำซ้ำ,ส่ง,แจกจ่าย,โอน,ดัดแปลง
                              ฯลฯ) จำเป็นที่จะต้องได้รับความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรด้วยเอกสารของสำนักงานฯ
                            </p>
                            <h4>การเปลี่ยนแปลงข้อมูลที่แสดงในเว็บไซต์</h4>
                            <p className="indent-7 text-justify">
                              สำนักงานฯสงวนสิทธิ์ที่จะไม่แจ้งให้ท่านทราบล่วงหน้า ถึงการเปลี่ยนแปลงข้อมูลในเว็บไซต์ ของสำนักงานฯ โดยการ
                              เปลี่ยนแปลงขึ้นอยู่กับดุลยพินิจของสำนักงานฯเพื่อให้เป็นประโยชน์ในการดำเนินงานของสำนักงานฯในการให้บริการต่อ
                              ผู้ใช้งานผ่านทางเว็บไซต์
                            </p>
                            <h4>ข้อห้าม</h4>
                            <div>
                              ในการใช้เว็บไซต์นี้ ห้ามมิให้ผู้ใช้กระทำสิ่งต่อไปนี้
                              <ul className="list-disc space-x-6">
                                <li className="hidden"></li>
                                <li>การกระทำที่ละเมิดทรัพย์สินหรือความเป็นส่วนตัวและอื่นๆของสำนักงานฯหรือบุคคลที่สาม หรือการกระทำที่อาจจะละเมิด นอกจากนี้ยังรวมไปถึงการกระทำใดๆที่ใช้ข้อมูลส่วนบุคคลบนเว็บไซต์นี้ด้วย</li>
                                <li>การกระทำที่ทำให้หรืออาจจะทำให้เกิดความเสียหาย,ความสูญเสียต่อสำนักงานฯหรือบุคคลที่สาม การใส่ร้าย,การหมิ่นประมาทหรือการข่มขู่สำนักงานฯหรือบุคคลที่สาม</li>
                                <li>การกระทำที่ขัดหรืออาจจะขัดต่อระเบียบและศีลธรรมของสังคม</li>
                                <li>การกระทำที่มีความผิดทางอาญา,เชื่อมโยงนำไปสู่ความผิดทางอาญา หรือการกระทำที่มีความเสี่ยงดังกล่าว</li>
                                <li>การกระทำลงทะเบียน,ส่งอีเมลแอดเดรสของบุคคลอื่นหรือข้อมูลส่วนบุคคลอื่นๆ หรือ การกระทำที่เป็นการปลอมแปลงเป็นบุคคลอื่น</li>
                                <li>การกระทำที่ทำให้ชื่อเสียงหรือความน่าเชื่อถือของสำนักงานฯหรือเว็บไซต์สำนักงานฯเสียหาย</li>
                                <li>การกระทำที่ใช้หรือสนับสนุนโปรแกรมที่เป็นอันตรายเช่นพวกไวรัสคอมพิวเตอร์ หรือการกระทำที่มีความเสี่ยงดังกล่าว</li>
                                <li>การกระทำที่ผิดหรืออาจจะผิดกฎหมาย,เทศบัญญัติ</li>
                                <li>การกระทำอื่นซึ่งสำนักงานฯพิจารณาว่าไม่เหมาะสม</li>
                                <li>การกระทำการคัดลอก ทำสำเนา แสดงผล แจกจ่าย ปรับปรุง เปลี่ยนแปลง ขาย เพื่อใช้ในวัตถุประสงค์อื่น นอกจากได้รับอนุญาตเป็นลายลักษณ์อักษรจากสำนักงานฯ</li>
                              </ul>
                            </div>
                            <h4>การเปลี่ยนแปลงข้อมูลที่แสดงในเว็บไซต์</h4>
                            <p>
                              การตีความตามเงื่อนไข และข้อกำหนดการใช้เว็บไซต์นี้ จะอยู่ภายใต้บังคับของกฎหมายแห่งราชอาณาจักรไทย
                            </p>
                          </div>
                          <div className="center-content items-center p-6 space-x-2 rounded-b border-t border-gray-200 text-center">
                            <button ref={acceptButton} tabIndex={1} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={props.onAcceptClick}>ตกลง</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}