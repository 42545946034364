import { ReactElement, MutableRefObject } from "react";
import { toast } from 'react-toastify';
import { CurrentUserInfo } from "../../models/security";

interface Props {
  user: CurrentUserInfo | null;
  userChild: ReactElement;
  unauthorizedChild: ReactElement;
  userRef: MutableRefObject<CurrentUserInfo | null>;
  notify?: () => boolean;
}

export default function UserAuth(props: Props): ReactElement {
  const enableNotification = props.notify ? props.notify() : false;

  if (props.user) {
    props.userRef.current = props.user ? { displayName: props.user?.displayName, email: props.user?.email } : null;
    if (enableNotification) toast.success(`Welcome, ${props.user.displayName || props.user.email}.`);
    return props.userChild;
  } else if (!props.user && props.userRef.current) {
    if (enableNotification) toast.success(`Good bye, ${props.userRef.current.displayName || props.userRef.current.email}`);
  } else {
    if (enableNotification) toast.error(`Please login.`);
  }
  props.userRef.current = null;
  return props.unauthorizedChild;
}