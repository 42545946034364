
import TenantPanel from '../panels/Tenant';
import './BackOffice.scss';
import {
  PencilSquareIcon,
  BuildingOffice2Icon,
  UsersIcon
} from '@heroicons/react/24/outline'

function BackOffice() {

  return (
    <div>
      <div className="mb-4 border-b border-gray-200 px-5">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
          <li className="mr-2" role="presentation">
            <button className="inline-block p-4 border-b-2 rounded-t-lg border-blue-600 text-blue-600" id="profile-tab" role="tab">
              <PencilSquareIcon className="inline h-4 w-4 -mt-1" />
              Bankrupcy
            </button>
          </li>
          <li className="mr-2" role="presentation">
            <button className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300" role="tab">
              <BuildingOffice2Icon className="inline h-4 w-4 -mt-1" />
              Tenants
            </button>
          </li>
          <li className="mr-2" role="presentation">
            <button className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300" role="tab">
              <UsersIcon className="inline h-4 w-4 -mt-1" />
              Users
            </button>
          </li>
        </ul>
      </div>
      <div id="myTabContent" className='px-5 w-full'>
        <div className="p-4 rounded-lg bg-gray-50" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <TenantPanel />
        </div>
        <div className="hidden p-4 rounded-lg bg-gray-50" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
          <p className="text-sm text-gray-500">This is some placeholder content the <strong className="font-medium text-gray-800">Dashboard tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling.</p>
        </div>
      </div>

    </div>
  );

}

export default BackOffice;
