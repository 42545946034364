import './CardInfinit.scss';

import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import logoKSm from '../../assets/images/logo_k_sm.png'

interface Props {
  show: boolean;
  message?: string;
}

export default function CardInfinitProgress(props: Props) {

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="infinit-progress bg-black bg-opacity-50 transition-opacity">
          <div className="content items-center justify-center">
            <div className="bg-blue-900 rounded-lg h-14 w-14 justify-center flex-col align-middle">
              <div className="animate-pulse flex h-14 w-14 items-center justify-center">
                <img alt='Loading ...' src={logoKSm}></img>
              </div>
            </div>
            <div className="break"></div>
            <div>
              <span className="text-white">{props.message}</span>
            </div>
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>
  )
}